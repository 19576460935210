import { unflatten, deepMerge } from '../utils/translations'
import en from './en_US'
import fr from './fr_FR'

export default defineI18nLocale(async (locale) => {
  const data = await $fetch('/api/monguichet/translations', {
    method: 'GET',
    params: { locale },
    headers: {
      Authorization: 'fakeToken',
    },
  })

  let localeMessages = {}
  if (locale === 'en') {
    localeMessages = deepMerge(en, unflatten(data))
  } else {
    localeMessages = deepMerge(fr, unflatten(data))
  }
  return localeMessages
})
